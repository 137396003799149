*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}
:root{
  --red:rgb(39, 182, 244);
  background-color: aliceblue;
}
.container{
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
}

@font-face {
  font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-ExtraLight.ttf");
    font-weight: 200;
  }
  
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-Light.ttf");
    font-weight: 300;
  }
  
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-Regular.ttf");
    font-weight: 400;
  }
  
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-Medium.ttf");
    font-weight: 500;
  }
  
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-SemiBold.ttf");
    font-weight: 600;
  }
  
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-Bold.ttf");
    font-weight: 700;
  }
  
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-ExtraBold.ttf");
    font-weight: 800;
  }
 
  @font-face {
      font-family: Inter, sans-serif;
    src: url("./assets/fonts/Inter_18pt-Black.ttf");
    font-weight: 900;
  }


  
@keyframes wave {
  0% {
    box-shadow: 0px 0px 20px 10px var(--red);
  }
  50% {
    box-shadow: 0px 0px 30px 15px var(--red);
  }
  100% {
    box-shadow: 0px 0px 20px 10px var(--red);
  }
}
@keyframes wavewhat {
  0% {
    box-shadow: 0px 0px 20px 10px #00D95F;
  }
  50% {
    box-shadow: 0px 0px 30px 15px #00D95F;
  }
  100% {
    box-shadow: 0px 0px 20px 10px #00D95F;
  }
}

.icon2 {
  position: fixed;
  right: 50px;
  top: 80%;
  z-index: 999;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 10.4px 5.94px 30.67px 0px #1b1b1b24;
  cursor: pointer;
  animation: wave 1.5s infinite ease-in-out;
  transition: transform 0.3s ease;
}
.icon3{
  position: fixed;
  top: 80%;
  right:92%;
  z-index: 999;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #00D95F;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 10.4px 5.94px 30.67px 0px #1b1b1b24;
  cursor: pointer;
  animation: wavewhat 1.5s infinite ease-in-out;
  transition: transform 0.3s ease;
}

.icon1 { animation-delay: 0.1s; }
.icon2 { animation-delay: 0.2s; }
.icon3 { animation-delay: 0.3s; }
.icon2{
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px); 
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.imag{
  width: 50px;
  height: 50px;
}
.pages a.active-link {
  color: var(--red);
  font-weight: bold;
  cursor: pointer;
}

.pages a {
  cursor: pointer;
}