@media(max-width:790px){
    .pages{
        display: none;
    }
    .section{
        flex-direction: column;
    }
    .remonts{
        width: 100%;
    }
    .teh{
        width: 100%;
        margin-top: 40px;
    }
    .about-card{
        flex-direction: column;
    }
    .cause{
        flex-direction: column;
    }
    .cause h2{
        background-color: aliceblue;
        width: 100%;
        top: 90px;
        padding-bottom: 10px;
    }
    .service-cards{
        grid-template-columns: 1fr;
    }
    .steps{
        flex-direction: column;
    }
    .step{
        width: 100%;
    }
    .logo{
        font-size: 30px;
        width: 50%;
    }
    .section h1{
        font-size: 30px;
    }
    .title{
        font-size: 30px;
    }
    .sale{
        flex-direction: column;
    }
    .sale img{
        width: 100%;
    }
    .icon2{
        right: 20px;
    }
    .icon3{
        right: 83%;
    }
}