.logo {
  font-size: 50px;
  font-weight: 700;
  color: var(--red);
}
.btn {
  border: none;
  border-radius: 12px;
  background-color: var(--red);
  padding: 12px 20px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.pages {
  display: flex;
  gap: 30px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(100px);
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 99;
}
.section {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section h1 {
  font-size: 40px;
  color: var(--red);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.section p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 15px;
}
.teh {
  width: 50%;
  object-fit: cover;
  object-position: center;
}
.remonts {
  width: 50%;
}
.title {
  color: var(--red);
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 40px;
}
.card {
  padding: 20px;
  border-radius: 15px;
  background-color: #b8e3f7;
}
.about-card {
  display: flex;
  gap: 20px;
}
.card h3 {
  margin-bottom: 12px;
  margin-top: 12px;
}
#service {
  margin-top: 100px;
  background-color: #b8e3f7;
  padding-top: 50px;
  padding-bottom: 50px;
}
.red {
  margin-top: 0;
}
.servie-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.service-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.servie-card h3 {
  color: #2f2f2f !important;
  font-size: 20px;
  font-weight: 600;
}
.servie-card p {
  line-height: 1.3;
  color: #afafaf;
  margin-top: 20px;
}
.servie-card h5 {
  color: var(--red);
  margin-top: 30px;
  font-size: 20px;
  font-weight: 400;
}

.reasons {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.reason {
  margin-bottom: 20px;
}

.reason strong {
  color: var(--red);
}
.cause {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.cause h2 {
  width: 90%;
  position: sticky;
  z-index: 1;
  top: 120px;
  align-self: flex-start;
}


.steps {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .step {
    width: calc(33.333% - 20px);
    border: 1px solid #d1d5db;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
  }
  
  .step-header {
    background-color: #1e90ff;
    color: #fff;
    padding: 10px 0;
    font-weight: bold;
  }
  
  .step-body {
    padding: 20px;
  }
  
  .icon {
    font-size: 2rem;
    width: 50px;

    color: #1e90ff;
    margin-bottom: 10px;
  }
  
  .step-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .step-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  .sales h2{
    font-weight: 400;
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
  }
  .sales p{
    color: #fff;
    margin-bottom: 20px;
  }
  .sale{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background-color: #4fc0f5;
    padding: 20px;
    border-radius: 20px;
  }
  .sale img{
    width: 40%;
    object-fit: cover;
    object-position: center;
  }










  .faq-container {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .faq-item {
    width: 100%;
  }
  
  .faq-question {
    width: 100%;
    padding: 20px;
    background :#4fc0f5;
    border: 1px solid #fff;
  border-radius: 8px;
    color: white;
    text-align: left;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .faq-question:hover {
    background :#4fc0f5;
  }
  
  .faq-question.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }
  
  .faq-question.active .arrow {
    transform: rotate(-135deg);
  }
  
  .faq-answer {
    background :#4fc0f5;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .faq-answer.active {
    padding: 20px;
    max-height: 300px;
    border: 1px solid var(--blue-v2);
    border-top: none;
  }

  .footer {
    background-color: #95a1a7;
    color: #fff;
    padding: 40px 0;
    font-family: Arial, sans-serif;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin: 20px;
    line-height: 1.5;
  }
  
  .footer-section h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .footer-section p, .footer-section a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
  }
  .cons{
    background-color: #4fc0f5;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contsxt{
    display: flex;
    gap: 10px;
  }
  .footer-section a:hover {
    color: #fff;
    text-decoration: underline;
  }
  .iconsd_footer{
    width: 25px;
  }
  .map-container {
    margin-top: 10px;
  }
  
  .map-container iframe {
    width: 100%;
    border-radius: 8px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #fff;
  }
.logo {
    font-size: 50px;
    font-weight: 700;
    color: var(--red);
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Кнопка */
  .btn {
    border: none;
    border-radius: 12px;
    background-color: var(--red);
    padding: 12px 20px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .btn:hover {
    transform: scale(1.1);
  }
  
  /* Разделы */
  .section {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    animation: slideUp 1s forwards;
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Карточки */
  .card {
    padding: 20px;
    border-radius: 15px;
    background-color: #b8e3f7;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Сервисные карточки */
  .servie-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transform: translateX(-50px);
    animation: slideInLeft 1s forwards;
  }
  
  @keyframes slideInLeft {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Ответы на FAQ */
  .faq-answer {
    background: #4fc0f5;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .faq-answer.active {
    padding: 20px;
    max-height: 300px;
    border: 1px solid var(--blue-v2);
    border-top: none;
  }
  
  /* Слайдера при прокрутке */
  #header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(100px);
    background: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 99;
    transform: translateY(-100%);
    animation: slideInDown 1s forwards;
  }
  
  @keyframes slideInDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Секция продаж */
  .sale {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background-color: #4fc0f5;
    padding: 20px;
    border-radius: 20px;
    opacity: 0;
    transform: translateX(50px);
    animation: slideInRight 1s forwards;
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Футер */
  .footer {
    background-color: #95a1a7;
    color: #fff;
    padding: 40px 0;
    font-family: Arial, sans-serif;
    opacity: 0;
    animation: fadeInFooter 1s forwards;
  }
  
  @keyframes fadeInFooter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Секция FAQ */
  .faq-question {
    width: 100%;
    padding: 20px;
    background: #4fc0f5;
    border: 1px solid #fff;
    border-radius: 8px;
    color: white;
    text-align: left;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .faq-question:hover {
    background: #4fc0f5;
  }
  
  .faq-question.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }
  
  .faq-question.active .arrow {
    transform: rotate(-135deg);
  }
  